import React, { useEffect, useRef } from 'react'

import { Link } from "react-router-dom"

import classes from './Nav.module.css'

import Backdrop from '../Backdrop/Backdrop'
import Aux from '../hoc/Aux'

const capitalize = s =>(`${s.charAt(0).toUpperCase()}${s.substring(1)}`)

const pathToLinkText = path =>(
	path === '' ? 'Intro' : capitalize(path)
)

const Nav = props => {
	const navRef = useRef(null)
	useEffect(()=>{
		navRef.current.classList.add(classes.Show)
	})
	return (
		<Aux>
			<Backdrop 
				onClick={()=>{
					props.toggleNav(!props.navOpen)
				}}
				show={props.navOpen}/>
			<div ref={navRef} className={classes.Nav}>
				<button 
					onClick={()=>{
						props.toggleNav(!props.navOpen)
					}}
					title="Custom menu icon using pseudo-selectors"
					className={classes.MenuToggle}>
					<span className={classes.ToggleIcon} />
				</button>
				<nav className={[
					classes.NavMenu,
					props.navOpen?classes.Open:''
				].join(' ')}>
					{props.navData.map(routeData=>(
						<Link 
							key={routeData.path}
							className={classes.NavLink}
							to={`/${routeData.path}`}>
							{pathToLinkText(routeData.path)}
						</Link>
					))}
				</nav>
			</div>
		</Aux>
	)
}

export default Nav