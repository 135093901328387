class Terminal{
	constructor(opts){
		this.cmds = opts.cmds;
		this.tgt = opts.tgt;
		this.speed = opts.speed || 80;
		this.sleep = opts.sleep || 3000;
		this.i = 0;
		this.resetDelay = opts.resetDelay || 0;
		this.cmdIdx = 0;
		//default to 1 blank line
		this.blankLine();
	}

	blankLine(){
		let line = document.createElement('div');
		//make zo span
		let userSpan = document.createElement('span');
		const userTxt = document.createTextNode('NR:// ');
		userSpan.classList.add('userTxt');
		userSpan.appendChild(userTxt);
		line.appendChild(userSpan);
		//make type target
		let typeTgt = document.createElement('span');
		line.appendChild(typeTgt);

		this.tgt.appendChild(line);
		this.typeTgt = typeTgt;
		//scroll next line into view
		this.tgt.scrollTop = this.tgt.scrollHeight;
	}

	run(){
		//get txt
		const cmd = this.cmds[this.cmdIdx];
		//if we haven't reached the end of the string
		if (this.i < cmd.length) {
			//put current letter into target
			this.typeTgt.innerHTML += cmd.charAt(this.i);
			//increment
			this.i++;
			//set timeout for next letter
			setTimeout(this.run.bind(this), this.speed);
		}else{
			setTimeout(()=>{
				//reset i
				this.i = 0;
				//toggle idx
				this.cmdIdx=(this.cmdIdx+1)%this.cmds.length;
				if(this.cmdIdx === 0){
					this.tgt.innerHTML = '';
				}
				//sleep
				setTimeout(()=>{
					this.blankLine();
					//start again
					setTimeout(this.run.bind(this), this.speed);
				},this.sleep);
			},this.resetDelay)
		}
	}
}

export default Terminal