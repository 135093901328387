import React from 'react'

import classes from './Intro.module.css'

import { Link } from "react-router-dom"

import somewhere from '../../somewhere.png'
import bowering from '../../bowering.jpeg'

import AspectBox from '../AspectBox/AspectBox'
import FadeInImage from '../FadeInImage/FadeInImage'

const intro = props => (
	<div className={classes.Introducing}>
		<AspectBox 
			customClass={[
				classes.AspectBox,
				classes.BoweringGradient
			].join(' ')}
			width={800} 
			ratio={3/4} 
			maxWidth="94%">
			<FadeInImage src={bowering} />
		</AspectBox>
		<div className={classes.DeepBlue} />
		<AspectBox 
			customClass={[
				classes.AspectBox,
				classes.SomewhereGradient
			].join(' ')} 
			width={400} 
			ratio={1}  
			maxWidth="76%">
			<FadeInImage src={somewhere}/>
		</AspectBox>
		<div className={classes.TheShadowOfNate} />
		<h1 className={classes.Nate}>
			<Link 
				style={{textDecoration:'none'}}
				to="/about">
					Nate 
				<span className={classes.Rojas}>Rojas...</span>
			</Link>
			
		</h1>
	</div>
)

export default intro