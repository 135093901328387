import React from 'react'

import { Link } from "react-router-dom"

const socialCTA = () =>(
	<div style={{textAlign:'right',fontSize:'18px'}}>
		<Link to="/social">&#8212; Get in touch</Link>
	</div>
)

export default socialCTA