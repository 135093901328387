import React, { useRef, useEffect } from 'react'

import classes from './Programming.module.css'

const minMax = (v,m,M) => ( Math.min(Math.max(m,v),M) || 0 )

class VeinGenerator {
	constructor(initData){
		this.numVeins = initData.numVeins
		this.svg = initData.svg
		this.activeVeins = 0
	}

	setNumVeins(n){
		this.numVeins = n
		this.generate()
	}

	setSvg(svg){
		this.svg = svg
	}

	generate(){
		if(this.activeVeins<this.numVeins){
			new Vein({
				svg:this.svg,
				x:Math.round(this.svg.getBoundingClientRect().width*Math.random()),
				generator:this
			}).explore()

			setTimeout(()=>{
				this.generate()
			},400*Math.random()+100)

			this.activeVeins += 1
		}
	}
}

class Vein {
	constructor(initData){
		this.svg = initData.svg
		this.x = initData.x
		this.y = 0
		this.numSegments = 0
		this.segments = []
		this.generator = initData.generator
	}

	drawNextSegment(){
		const svg = this.svg, x = this.x, y = this.y
		const multiplier = Math.random() > 0.5 ? -1 : 1
		const isDiagonal = Math.random() > 0.8
		const offsetUnit = 25
		const xOffset = (
			isDiagonal ? 
			(
				(x + offsetUnit*multiplier >= svg.getBoundingClientRect().width) || (x + offsetUnit*multiplier <= 0) ?
				offsetUnit*multiplier*-1 : offsetUnit*multiplier
			): 
			0
		)
		const newX = x + xOffset, newY = y + offsetUnit
		const newSegment = document.createElementNS('http://www.w3.org/2000/svg','line')
		newSegment.setAttribute('x1',x)
		newSegment.setAttribute('y1',y)
		newSegment.setAttribute('x2',newX)
		newSegment.setAttribute('y2',newY)
		newSegment.setAttribute('stroke','blue')
		newSegment.setAttribute('class',[
			classes.VeinSegment,
			isDiagonal ? classes.Diagonal : classes.Vertical
		].join(' '))
		svg.appendChild(newSegment)
		this.x = newX
		this.y = newY
		this.numSegments += 1
		this.currentSegment = newSegment
		this.segments.push(newSegment)
	}

	explore(){
		this.drawNextSegment()
		this.currentSegment.addEventListener(
			"webkitAnimationEnd",
			()=>{
				if(this.numSegments<8){
					this.explore()
				}else{
					this.destroy()
				}
			},
			false
		);
	}

	destroy(){
		this.segments.forEach(s=>{
			s.setAttribute('stroke','transparent')
		})
		setTimeout(()=>{
			while(this.segments.length){
				this.segments.pop().remove()
			}
			this.segments = null
			this.generator.activeVeins -= 1
			this.generator.generate()
		},400)
		
	}
}

const Programming = props => {
	const svgRef = useRef(null)
	const veinGenerator = new VeinGenerator({
		numVeins: 10
	})
	useEffect(()=>{
			veinGenerator.setSvg(svgRef.current)
			veinGenerator.generate()
		})
	return (
		<div>
			<input 
				style={{
					display:'block',
					width:'100%',
					padding:'4px 8px',
					border:'1px solid #eee',
					borderRadius:'4px',
					marginBottom:'8px'
				}}
				placeholder="# veins"
				onKeyUp={ e => {
					const v = e.target.value
					const m = 0
					const M = 100
					const numVeins = (
						v === '' ? 10 :
						minMax(Number(v),m,M)
					)
					const newVal = (
						v === '' ? '' :
						numVeins
					)
					e.target.value = newVal
					veinGenerator.setNumVeins(numVeins)
				}}/>
			<svg ref={svgRef} className={classes.VeinsCanvas} />
			<p style={{margin:0}}>~6hrs to develop this effect [GitHub]</p>
		</div>
	)
}

export default Programming