import React from 'react'

import classes from './AspectBox.module.css'

const aspectBox = props =>{
	const unit = props.unit || 'px'
	const paddingBottom = `${props.ratio*100}%`
	const width = `${props.width}${unit}`
	const maxWidth = props.maxWidth
	const backgroundColor = props.backgroundColor
	return (
		<div 
			style={{...props.style,width,maxWidth}}
			className={[
				classes.AspectBox,
				props.customClass
			].join(' ')}>
			<div style={{
				width:'100%',
				height:0,
				backgroundColor,
				paddingBottom
			}}>
				{props.children}
			</div>
		</div>
	)
}

export default aspectBox