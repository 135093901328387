import React from 'react'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

import 'katex/dist/katex.min.css'
import { InlineMath } from 'react-katex'

import BioBower from '../../BioBower.jpeg'
import under from '../../under.png'
import above from '../../above.png'
import turbulant_slice from '../../turbulant_slice.jpeg'

import DeepPreimages from '../../documents/deep-preimages.pdf'

import AspectBox from '../AspectBox/AspectBox'
import FadeInImage from '../FadeInImage/FadeInImage'


import AI from './Passions/AI/AI'
import Mathematics from './Passions/Mathematics/Mathematics'
import Programming from './Passions/Programming/Programming'
import Photography from './Passions/Photography/Photography'
import Design from './Passions/Design/Design'
import Reading from './Passions/Reading/Reading'

import classes from './About.module.css'

const PassionsMap = {
	'Mathematics':<Mathematics />,
	'Programming':<Programming />,
	'AI/ML':<AI/>,
	'Design':<Design />,
	'Photography':<Photography />,
	'Reading':<Reading />,
	'Fitness':'Hiking, Yoga, TKD, etc...'
}

const about = props =>{
	return (
		<div className={classes.AboutContainer}>
			<header className={classes.AboutHeader}>
				<div className={classes.BlueBand}/>
				<AspectBox 
					customClass={classes.BioBower}
					backgroundColor="rgb(37,39,37)" 
					width={300} 
					ratio={124/300} 
					maxWidth="100%">
					<FadeInImage src={BioBower} />
				</AspectBox>
				<h1 className={classes.AboutHeading}>
					Bio
					<small>graphy</small>
				</h1>
			</header>
			<div className={classes.TLDR}>
				<h2>too long - did not read</h2>
				<p>
					Nate is an excellent coder, math enthusiast & lifelong learner.
				</p>
			</div>
			<div className={classes.Content}>
				<div className={classes.ContentBlock}>
					<AspectBox 
						backgroundColor="rgb(37,39,37)" 
						width={512} 
						ratio={23.8/400} 
						maxWidth="100%">
						<FadeInImage 
							style={{
								width:'100%',
								display:'block'
							}}
							src={turbulant_slice} />
					</AspectBox>
					<h2 style={{margin:'8px 0',fontFamily:'monospace'}}>
						Introducing...
					</h2>
					<AspectBox 
						backgroundColor="rgb(37,39,37)" 
						width={512} 
						ratio={23.8/400} 
						maxWidth="100%">
						<FadeInImage 
							style={{
								width:'100%',
								display:'block'
							}}
							src={turbulant_slice} />
					</AspectBox>
					<h3 className={classes.Heading3}>General</h3>
					<p>
						Nate Rojas is a disciplined self-taught programmer. He codes almost every day and continuously learns about new technologies.
					</p>
					<p style={{textAlign:'justify'}}>
						<strong>Passions: </strong>
						{Object.keys(PassionsMap).map((k,i)=>{
							return (
								<OverlayTrigger
									key={k}
									placement="top"
									trigger="click"
									rootClose
									overlay={
										<Popover style={{
											padding:'16px',
											width:'400px',
											maxWidth:'90%'
										}}>
											{PassionsMap[k]}
										</Popover>

									}>
									<span className={classes.Passion}>
										{`${
											k
										}${
											i===(Object.values(PassionsMap).length-1)?
											'.':','
										}`}
									</span>
								</OverlayTrigger>
							)
						})}
						<small className="d-block text-muted mt-1 text-right">click a passion</small>
					</p>
					<h3 className={classes.Heading3}>Nate's Present</h3>
					<p style={{textAlign:'justify'}}>
						<strong>Current studies: </strong>
						Containerizing and deploying complex applications built on top of modern front & back end technologies.
					</p>
					<p style={{textAlign:'justify'}}>
						<strong>Current research interest: </strong>
						Hacking Neural Networks* & Real time human pose understanding.
					</p>
				</div>
				<div className={classes.Divider} />
				<div className={classes.ContentBlock}>
					<h3 className={classes.Heading3}>
						The Plan
					</h3>
					<p style={{marginBottom:0}}>
						Nate is currently developing his skills as a full stack web developer with an emphasis on deploying scalable apps. On top of these skills he intends to continue building his knowledge of machine learning & AI so that he can integrate modern technologies into usefull apps.
					</p>
					<div className={classes.QuoteContainer}>
						<AspectBox 
							backgroundColor="rgb(37,39,37)" 
							width={512} 
							ratio={29/400} 
							maxWidth="100%">
							<FadeInImage 
								style={{
									width:'100%',
									display:'block'
								}}
								src={above} />
						</AspectBox>
						<blockquote className={classes.Quote}>
							"if all i had was euler's number i think that i'd be all the better for it."
						</blockquote>
						<AspectBox 
							backgroundColor="rgb(220,190,190)" 
							width={512} 
							ratio={29/400} 
							maxWidth="100%">
							<FadeInImage 
								style={{
									width:'100%',
									display:'block'
								}}
								src={under} />
						</AspectBox>
					</div>
					<h3 className={classes.Heading3}>
						The Dream
					</h3>
					<p>
						Nate seeks to become a pure and applied Mathematician with an emphasis on theoretical & practical AI - one day retiring as a math instructor. 
					</p>
					<div style={{
						textAlign:'right',
						marginTop:'16px'
					}}>
						QED <InlineMath>\blacksquare</InlineMath>
					</div>
				</div>
			</div>
			<div style={{color:'#777',marginTop:'32px',paddingTop:'16px',borderTop:'1px solid #EEE'}}>
				* Analytically exploring their vulnerabilities <a target="_blank" rel="noopener noreferrer" href={DeepPreimages}>[link]</a>
			</div>
		</div>
	)
}

export default about