import React, {useState} from 'react'

import Modal from 'react-bootstrap/Modal'

import AspectBox from '../../../AspectBox/AspectBox'
import FadeInImage from '../../../FadeInImage/FadeInImage'

import Cathedral from '../../../../lyapunovs_cathedral.png'

const AI = props => {
	const [show,setShow] = useState(false)
	return (
		<div style={{textAlign:'justify'}}>
			<div style={{
				width:'128px',
				float:'left',
				margin:'0 8px 8px 0'
			}}>
				<AspectBox 
					backgroundColor="rgb(63,45,90)" 
					width={120} 
					ratio={755/998} 
					maxWidth="100%">
					<FadeInImage
						style={{
							width:'100%',
							borderRadius:'4px',
							overflow:'hidden'
						}}
						src={Cathedral} 
						alt="A Neural Style Transfer"/>
				</AspectBox>
				<span 
					onClick={()=>setShow(true)}
					style={{color:'blue',cursor:'pointer'}}>
					What's this?
				</span>
			</div>
			<strong>Nate is respectful</strong>
			<p>
				As part of his passion for AI/ML Nate participates on the AI Stackexchange.
			</p>
			<p style={{textAlign:'right'}}>
				<a 
					target="_blank"
					rel="noopener noreferrer"
					style={{
						display:'inline-block'
					}} 
					href="https://ai.stackexchange.com/users/28343/respectful?tab=profile">
					Check out his answers
				</a>
			</p>
			<Modal
				style={{zIndex:'1070'}}
				show={show}
				size="lg"
				onHide={()=>setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title id="example-custom-modal-styling-title">
						Lyapunov's Cathedral
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AspectBox 
						backgroundColor="rgb(63,45,90)" 
						width={766} 
						ratio={755/998} 
						maxWidth="100%">
						<FadeInImage
							style={{
								width:'100%',
								borderRadius:'4px',
								overflow:'hidden'
							}}
							src={Cathedral} 
							alt="A neural style transfer named: Lyapunov's Cathedral"/>
					</AspectBox>
					<p style={{textAlign:'center'}}>
						The above image is a <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Neural_Style_Transfer">neural style transfer</a> of <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Lyapunov_fractal">Lyapunov's Fractal</a> unto a cathedral.
					</p>
				</Modal.Body>
			</Modal>
		</div>
	)
}
export default AI