import React from 'react'

import NebulousSlice from '../../NebulousSlice.png'

import AspectBox from '../AspectBox/AspectBox'
import FadeInImage from '../FadeInImage/FadeInImage'

import classes from './Social.module.css'

const social = props => (
	<div className={classes.BusinessCard}>
		<h1 className={classes.Heading}>Greetings...</h1>
		<AspectBox 
			backgroundColor="rgb(63,45,90)" 
			width={400} 
			ratio={14/400} 
			maxWidth="100%">
			<FadeInImage 
				style={{
					width:'100%',
					display:'block'
				}}
				src={NebulousSlice} />
		</AspectBox>
		<div style={{
			display:'flex',
			justifyContent:'space-between'
		}}>
			<p>rojasinate@gmail.com</p>
			<span>-</span>
			<a 
				target="_blank" 
				rel="noopener noreferrer"
				href="https://www.linkedin.com/in/nate-rojas/">
				LinkedIn
			</a>
		</div>
	</div>
)

export default social