import React from 'react'

import classes from './Experience.module.css'

import ExperientialWeight from '../../ExperientialWeight.jpeg'
import NebulousSlice from '../../NebulousSlice.png'

import Programming from './BlockContent/Programming/Programming'
import AI from './BlockContent/AI/AI'

import {
	ExperienceBlock,
	Header,
	Badges
} from './ExperienceBlock/ExperienceBlock'

import AspectBox from '../AspectBox/AspectBox'
import FadeInImage from '../FadeInImage/FadeInImage'

const experienceData = [
	{
		heading:'Web Development',
		time:'7+ years',
		badgeItems:[
			'Javascript',
			'HTML',
			'CSS/SCSS',
			'PHP',
			'Databases',
			'Node.js',
			'React',
			'Redux',
			'JSON',
			'etc.'
		],
		body:(
			<div className={classes.WebCols}>
				<div className={classes.WebCol}>
					<h3>Maintaining Front End Websites</h3>
					<p>
						Optimizing site performance for SEO, 
						structuring funnels to match click through data, writing fully responsive HTML & CSS (using mobile first approach)
					</p>
					<h3>Maintaining Multi-page/SPA web apps</h3>
					<p>
						Interacting with server APIs, writing APIs, optimizing APIs, Structuring state/data around business logic
						for maintainable code & easy feature additions. 
					</p>
					<h3>Data persistence</h3>
					<p>
						Developing correctly structured DB relations (normalizing), molding new APIs to legacy schemas to mitigate code rot, migrating data to new schemas, writing data recovery scripts. Writing and optimizing complex queries (eg SQL, PostGresql).
					</p>
				</div>
				<div className={classes.ColDivider} />
				<div className={classes.WebCol}>
					<h3>Leveraging Third Party Software/Services</h3>
					<p>
						Creating feature rich live video classes with Daily.co WebRTC service.
						Full stack payments with stripe (ie UI & API from cart to purhcase). 
						Integrating granular analytics (FB, Google etc) into web UI interactions. 
						Capturing user data and maintaining up-to-date records on Mailchimp.
						Displaying data in graph format with Chart.js, D3.js, Google Charts.
					</p>
					<p>
						Missing something? If it has docs, Nate will get it done. 
					</p>
					<h3>On The Job Improvement</h3>
					<p>
						Reading docs*, continuously searching for best practices & optimization tricks. 
					</p>
					<small>*Also writing docs.</small>
				</div>
			</div>
		)//end body jsx
 	},{
 		heading:'Machine Learning & AI',
 		time:'5+ years',
 		badgeItems:[
 			'Neural Networks',
 			'PyTorch',
 			'TensorFlow',
 			'Numpy',
 			'SVM',
 			'Linear Algebra',
 			'Data Engineering'
 		],
 		body:<AI/>
 	},{
 		heading:'Programming - General',
 		time:'8+ years',
 		badgeItems:[
 			'OOP',
 			'Data-structures',
 			'Algorithms',
 			'Complexity Analysis',
 			'Scripting',
 			'CLI',
 			'Git'
 		],
 		body:<Programming />
 	},{
 		heading:'Self Improvement',
 		time:'lifelong',
 		badgeItems:[
 			'Self-education',
 			'Strategic Learning',
 			'Broad Understanding',
 			'Research',
 			'Certifications'
 		],
 		body:(
 			<div>
 				<h3>Self-study</h3>
 				<p>
 					For Nate, learning is not a means to an end - rather it is an endless passion. 
 					From watching free Stanford lectures in the middle of the night to reading about 
 					the philosophy of mathematics Nate has a broad understanding of pure and applied 
 					sciences. Nate also likes to dive deep into "the abyss*" of mathematics just to 
 					witness the wonder of subtlety. 
 				</p>
 				<p>
 					<strong>Current self-learning path</strong>: Re-read "Artificial Intelligence: A Modern Approach", continue studing <em>Mathematical Logic</em> (Kleene), 
 					study Digital Logic & Computer architecture with an emphasis on FPGAs, and more...
 				</p>
 				<p>
 					What does Nate seek to do with this knowledge? Contribute original research
 					and resources to the AI/Cognitive Science field(s). 
 				</p>
 				
 				<h3>Providing Tools</h3>
 				<p>
 					Knowledge of the science is not enough for Nate. Nate constantly analyzes learning and knowledge presentation
 					to see if there are ways that facts/understandings can be distilled into applications/resources that rapidify
 					the knowledge acquistion process (eg writing rigorous and well worded proofs to key results on forums). That is,
 					Nate's attempts at self improvement extend to helping others improve themselves as well.
 				</p>
 				<h3>Strategic Learning</h3>
 				<p>
 					In order to progress towards his goals most optimally, Nate has mastered the art of "strategic learning." This means that Nate knows how to learn the right thing to accomplish
 					his immediate goals & add value to himself as a team member.
 				</p>
 				<small>
 					*A term borrowed from one of Dr. Osgood's (Stanford) lectures used to describe deep and seemingly arcane mathematics.
 				</small>
 			</div>
 		)//end body jsx
 	}
]

const experience = props =>(
	<div 
		id="experience"
		style={{width:'960px',maxWidth:'94%',margin:'auto',paddingBottom:'180px'}}>
		<div className={classes.Jumbo}>
			<AspectBox 
				backgroundColor="rgb(37,39,37)" 
				width={960} 
				ratio={273.59/960} 
				maxWidth="100%">
				<FadeInImage 
					alt=""
					style={{
						width:'100%',
						display:'block'
					}}
					src={ExperientialWeight} />
			</AspectBox>
			<div className={classes.HeadingShadow} />
			<h1 className={classes.Heading}>
				<AspectBox 
					backgroundColor="rgb(63,45,90)" 
					width={400} 
					ratio={14/400} 
					maxWidth="100%">
					<FadeInImage 
						style={{
							width:'100%',
							display:'block'
						}}
						src={NebulousSlice} />
				</AspectBox>
				Experience
				<small> + Skills... </small>
				<AspectBox 
					backgroundColor="rgb(63,45,90)" 
					width={400} 
					ratio={14/400} 
					maxWidth="100%">
					<FadeInImage 
						style={{
							width:'100%',
							display:'block'
						}}
						src={NebulousSlice} />
				</AspectBox>
			</h1>
		</div>
		{experienceData.map((dat,i)=>(
			<ExperienceBlock
				key={i}
				excludeDots={i===experienceData.length-1}>
				<Header heading={dat.heading} time={dat.time} />
				<Badges badgeItems={dat.badgeItems} />
				{dat.body}
			</ExperienceBlock>
		))}
	</div>
)

export default experience