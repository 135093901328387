import React, { useState } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import './App.css';

import Intro from './components/Intro/Intro'
import Nav from './components/Nav/Nav'
import About from './components/About/About'
import Experience from './components/Experience/Experience'
import Social from './components/Social/Social'

const NAV_DATA = [
	{
		path:'',
		component:<Intro />
	},{
		path:'about',
		component:<About />
	},{
		path:'experience',
		component:<Experience />
	},{
		path:'social',
		component:<Social />
	}
]

const App = () => {
	const [navOpen,toggleNav] = useState(false)
	return (
		<Router>
			<Nav
				navData={NAV_DATA}
				navOpen={navOpen}
				toggleNav={toggleNav}/>
			<div id="Content">
				<Switch>
					{NAV_DATA.map(routeData=>(
						<Route exact
							key={routeData.path} 
							path={`/${routeData.path}`}>
							{routeData.component}
						</Route>
					))}
				</Switch>
			</div>
			
			<small style={{
				position:'fixed',
				bottom:'4px',
				right:'4px',
				color:'#777'
			}}>
				&#169; 2020 Nate Rojas
			</small>
		</Router>
	)
}


export default App;
