import React from 'react'

const reading = props => (
	<div>
		<h5>Top Picks</h5>
		<p>
			<strong>Favorite Fiction</strong> - <em>Musashi</em> by Eji Yoshikawa.
		</p>
		<p>
			<strong>Most Influential</strong> - <em>Godel, Escher, Bach</em> by Douglas Hofstadter.
		</p>
		<p>
			<strong>Most Read</strong> - <em>Watership Down</em> by Richard Adams.
		</p>
	</div>
)

export default reading