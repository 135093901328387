import React from 'react'

import TheSquare from '../TheSquare/TheSquare'
import SocialCTA from '../../SocialCTA/SocialCTA'

import classes from './ExperienceBlock.module.css'

export const Header = props =>(
	<header>
		<h2>
			<span>
				<TheSquare/>
				{props.heading}
			</span>
			<small>
				{props.time}
			</small>
		</h2>
	</header>
)

export const Badges = props =>(
	<ul className={classes.BadgeList}>
		{props.badgeItems.map(badge=>(
			<li key={badge}>{badge}</li>
		))}
	</ul>
)

export const ExperienceBlock = props =>(
	<React.Fragment>
		<div className={classes.ExperienceBlock}>
			{props.children}
			<SocialCTA/>
		</div>
		{
			!props.excludeDots ?
			<div 
				style={{
					fontSize:'32px',
					textAlign:'center',
					margin:'16px auto'
				}}>
				&#x22EE;
			</div> : 
			null
		}
		
	</React.Fragment>
)