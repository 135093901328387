import React from 'react'

import AspectBox from '../../AspectBox/AspectBox'
import FadeInImage from '../../FadeInImage/FadeInImage'

import src from '../../../TheSquare.png'

const theSquare = props => (
	<AspectBox 
		style={{
			display:'inline-block',
			marginRight:'8px'
		}}
		backgroundColor="rgb(37,39,37)" 
		width={28} 
		ratio={1} 
		maxWidth="100%">
		<FadeInImage 
			style={{
				width:'100%',
				display:'block'
			}}
			src={src} />
	</AspectBox>
)

export default theSquare