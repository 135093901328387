import React from 'react'


import AspectBox from '../../../AspectBox/AspectBox'
import FadeInImage from '../../../FadeInImage/FadeInImage'

import NebulousPrePrint from '../../../../NebulousPrePrint.png'

const nebulousPrePrint = props => (
	<div>
		<AspectBox 
			backgroundColor="#fff" 
			ratio={327/512} 
			maxWidth="100%">
			<FadeInImage
				style={{
					width:'100%',
				}}
				src={NebulousPrePrint} 
				alt="Intro Page"/>
		</AspectBox>
		<p>
			<strong>Theme</strong> - Nebulous Pre-print
		</p>
		<p>
			<strong>Inspiration</strong> - The ample whitespace of two column research papers.
		</p>
		<small>Designed & developed by NateRojas</small>
	</div>
)

export default nebulousPrePrint