import React from 'react'

import AspectBox from '../../../AspectBox/AspectBox'
import FadeInImage from '../../../FadeInImage/FadeInImage'

import BlueHome from '../../../../BlueHome.jpeg'

const photography = props => (
	<div>
		<strong>Blue Home</strong>
		<AspectBox 
			backgroundColor="rgb(100,170,190)" 
			ratio={600/800} 
			maxWidth="100%">
			<FadeInImage
				style={{
					width:'100%',
				}}
				src={BlueHome} 
				alt="A deep blue scene of cold beauty"/>
		</AspectBox>
		<p className="text-justify mt-1">
		I used to live in a blue home somewhere in the trees. 
		Now I have a mountain view elsewhere from that azure place.
		</p>
	</div>
)


export default photography