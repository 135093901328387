import React from 'react'
import Carousel from 'react-bootstrap/Carousel'

import AbstractDavid from '../../../../abstract-david.png'
import Cathedral from '../../../../cathedral_in_the_fog.png'
import LinearAdversary from '../../../../adam_linear_adversarial_examples.png'

import AStar from '../../../../documents/a-star-optimality.pdf'
import DeepPreimages from '../../../../documents/deep-preimages.pdf'
import BackProp from '../../../../documents/backprop.pdf'

import classes from './AI.module.css'

import AIJoke from './AIJoke'

const AI = props => (
	<div>
		<div>
			<div className={classes.StyleTransfers}>
				<Carousel
					style={{
						borderRadius:'4px',
							overflow:'hidden'
					}}>
					<Carousel.Item interval={1000}>
						<img alt="A neural style transfer" className="w-100" src={AbstractDavid} />
					</Carousel.Item>
					<Carousel.Item interval={1000}>
						<img alt="A neural style transfer" className="w-100" src={Cathedral} />
					</Carousel.Item>
				</Carousel>
			</div>
			
			<div>
				<h3>Neural Networks</h3>
				<p className="text-justify">
					Nate is very comfortable with deep networks &mdash; he understands the iterative tuning process and has a firm grasp
					of the mathematics that define neural network internals. 
				</p>
				<h3>In the know...</h3>
				<p className="text-justify">
					From leveraging free online courses (eg MIT OCW) to self-study, Nate is constantly improving his undestanding of the 
					theoretical underpinnings of machine learning. On top of that, Nate stays up-to-date on the latest open source
					technologies for deploying neural networks (eg TensorFlow.js). 
				</p>
			</div>
		</div>
		<hr className="my-5"/>
		<img alt="Adversarial examples" className="w-100" src={LinearAdversary} />
		<p className="text-justify">
			<strong>Above</strong>: As part of his passion for machine learning and AI, Nate conducts his own research. Of particular interest to Nate 
			are the vulnerabilities of Neural Networks (eg adversarial examples). In short, this image is a "single shot, optimization free" adversarial example for a single
			layer Neural Network  - ie the forward function is a linear operation followed by Cross-entropy loss function. <strong>tgt</strong>: desired classification, <strong>out</strong>: predicted class. <a target="_blank" rel="noopener noreferrer" href={DeepPreimages}>[link]</a> (this research is still being conducted).
		</p>
		<AIJoke />
		<p className="text-justify">
			<strong>Current project</strong>: Browser deployable hand tracking and pose estimation for "artifact free" UI. This project is being built using a combination of open source
			models and engineered data. In order to expedite the data creation process Nate is building a simple data labeling front end.
		</p>
		<h3>Some Resources</h3>
		<p>
			A* optimality proof <a target="_blank" rel="noopener noreferrer" href={AStar}>[link]</a>, vetorized back prop equations derivation <a target="_blank" rel="noopener noreferrer" href={BackProp}>[link]</a>.
		</p>
		</div>
)


export default AI