import React from 'react'

import classes from './Backdrop.module.css'

const backdrop = props =>(
	<div 
		onClick={props.onClick}
		className={[
			classes.Backdrop,
			props.show?classes.Show:''
		].join(' ')}/>
)

export default backdrop