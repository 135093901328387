import React from 'react'

import classes from './FadeInImage.module.css'

const fadeInImage = props =>(
	<img 
		alt={props.alt || ""}
		style={props.style}
		onLoad={e=>e.target.classList.add(classes.Show)}
		src={props.src} 
		className={classes.FadeInImage} />
)

export default fadeInImage