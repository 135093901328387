import React,{useRef,useEffect} from 'react'

import Terminal from '../../../../extras/Terminal'

import classes from './AIJoke.module.css'

const AIJoke = props =>{
	const terminalRef = useRef(null)
	useEffect(()=>{
		if(!terminalRef.current) return
		const loading = '#'.repeat(20)
		const cmds = [
			'BOOTING',
			loading,
			'downloading neural network',
			loading,
			'downloading data',
			loading,
			'augmenting data',
			loading,
			'optimizing',
			loading,
			'deploying to edge',
			loading,
			'!!!! WARNING !!!!',
			'ADVERSARIAL ATTACK DETECTED',
			'MITGATING',
			loading,
			loading,
			loading,
			'attack mitigated',
			'this cat is definitely an iguanna',
			'not funny...',
			'ceasing all inferences',
			loading,
			'REBOOTING',
			loading
		]
		new Terminal({
			cmds,
			resetDelay:3000,
			sleep:100,
			speed:40,
			tgt:terminalRef.current
		}).run()
	})
	return (
		<div className={classes.AIJokeContainer}>
			<div ref={terminalRef} className={classes.AIJoke}>
			</div>
			<div className={classes.TerminalGrit}/>
		</div>
	)
}

export default AIJoke