import React from 'react'

import 'katex/dist/katex.min.css'
import { InlineMath } from 'react-katex'

const mathematics = props => (
	<div>
		<h4>Favorite Equality</h4>
		<InlineMath>
			{"e^x = \\displaystyle\\sum_{n=0}^\\infty\\frac{x^n}{n!}"}
		</InlineMath>
		<p>Nate loves how you can use this result to derive Euler's identity. Upon learning this fact, Nate became hooked on math.</p>
	</div>
)

export default mathematics