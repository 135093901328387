import classes from './Blueprint.module.css'

class Blueprint{
	constructor(drawTgt){
		drawTgt.classList.add(classes.Blueprint)
		this.drawTgt = drawTgt;
	}

	drawRect(x,y,w,h){
		//draw rectantle
		var r = document.createElementNS('http://www.w3.org/2000/svg','rect');
		r.setAttribute('x',x);
		r.setAttribute('y',y);
		r.setAttribute('width',w);
		r.setAttribute('height',h);
		r.setAttribute("fill", "none");
		r.setAttribute("stroke", "#ccc");
		this.drawTgt.appendChild(r);
	}

	drawFrame(x,y,w,h){
		this.drawRect(x,y,w,h);
		//draw lines through it
		var l1 = document.createElementNS('http://www.w3.org/2000/svg','line');
		l1.setAttribute('id','line2');
		l1.setAttribute('x1',x);
		l1.setAttribute('y1',y);
		l1.setAttribute('x2',x+w);
		l1.setAttribute('y2',y+h);
		l1.setAttribute("stroke", "#ccc");
		this.drawTgt.appendChild(l1);

		var l2 = document.createElementNS('http://www.w3.org/2000/svg','line');
		l2.setAttribute('id','line2');
		l2.setAttribute('x1',x);
		l2.setAttribute('y1',y+h);
		l2.setAttribute('x2',x+w);
		l2.setAttribute('y2',y);
		l2.setAttribute("stroke", "#ccc");
		this.drawTgt.appendChild(l2);
	}

	drawTxt(x,y,txt){
		var t = document.createElementNS('http://www.w3.org/2000/svg','text');
		t.setAttribute('x',x);
		t.setAttribute('y',y);
		t.setAttribute('dx',8);
		t.setAttribute('dy',-8);
		t.setAttribute("fill", "#ccc");
		const txtNode = document.createTextNode(txt);
		t.appendChild(txtNode);
		this.drawTgt.appendChild(t);
	}

	drawCirc(cx,cy,r){
		var v = document.createElementNS('http://www.w3.org/2000/svg','circle');
		v.setAttribute('cx',cx);
		v.setAttribute('cy',cy);
		v.setAttribute('r',r);
		v.setAttribute('fill','none');
		v.setAttribute("stroke", "#ccc");
		this.drawTgt.appendChild(v);
	}

	drawGraph(V,adj){
		//curry to group parameter types
		//the first parameters are the absolute graph definitions
		//the next parts are the positioning and scaling
		return (x0,y0,ulm)=>{
			V.forEach((v,i)=>{
				this.drawTxt(v[0]*ulm+x0*ulm,v[1]*ulm+y0*ulm,i+1);
				this.drawCirc(v[0]*ulm+x0*ulm,v[1]*ulm+y0*ulm,4);
			})
			//loop over lists
			adj.forEach((l,srcIdx)=>{
				//loop over tgt lsit
				const src = V[srcIdx];
				l.forEach(tgtIdx=>{
					const tgt = V[tgtIdx-1];
					this.drawLine(src[0]*ulm+x0*ulm,src[1]*ulm+y0*ulm,tgt[0]*ulm+x0*ulm,tgt[1]*ulm+y0*ulm);
				})
				
			})
		};//end currying function
	}

	drawLine(x1,y1,x2,y2){
		var l = document.createElementNS('http://www.w3.org/2000/svg','line');
		l.setAttribute('id','line2');
		l.setAttribute('x1',x1);
		l.setAttribute('y1',y1);
		l.setAttribute('x2',x2);
		l.setAttribute('y2',y2);
		l.setAttribute("stroke", "#ccc");
		this.drawTgt.appendChild(l);
	}

	drawTextBlock(x0,y0,w,n,ulm){
		for (var i = 0; i < n; i++) {
			this.drawLine(x0*ulm,y0*ulm+i*ulm/2,x0*ulm+w*ulm,y0*ulm+i*ulm/2);
		}
	}

	drawHand(x0,y0){
		//xo is x-offset in ulm units
		//yo is y-offset in ulm units
		//calculate unit length multiplier
		const svgWidth = this.drawTgt.getBoundingClientRect().width;
		//set height to 3/2*width
		this.drawTgt.setAttribute('height',svgWidth*1.333);
		const ulm = svgWidth/9.5;
		//add some frames using ulm units
		this.drawFrame(6*ulm,10,3*ulm,40);
		//vertexes based on a unit 1
		const V = [
			[1,10],//1
			[3.3,10],//2
			[.67,9],//3
			[1.5,8.8],//4
			[2.5,8.7],//5
			[3.3,8.8],//6
			[4,9],//7
			[0.1,7.25],//8
			[1.5,6],//9
			[2.7,6.1],//10
			[3.8,6.3],//11
			[4.9,6.7],//12
			[0,5.3],//13
			[1.5,3.9],//14
			[2.8,3.8],//15
			[4.05,4],//16
			[5.45,4.5],//17
			[0.05,4],//18
			[1.5,2],//19
			[2.9,1.8],//20
			[4.2,2],//21
			[5.5,2.5],//22
			[1.5,1],//23
			[2.95,.5],//24
			[4.25,1],//25
			[5.5,1.5],//26
		];
		//adjacency list 1 indexed
		const adj = [
			[3,2],//1
			[7],//2
			[4,8],//3
			[9,5],//4
			[6,10],//5
			[7,11],//6
			[12],//7
			[9,13],//8
			[10,14],//9
			[15,11],//10
			[12,16],//11
			[17],//12
			[18],//13
			[19],//14
			[20],//15
			[21],//16
			[22],//17
			[],//18
			[23],//19
			[24],//20
			[25],//21
			[26],//22
			[],//23
			[],//24
			[],//25
			[],//26
		];
		this.drawGraph(V,adj)(x0,y0,ulm);
	}

	drawBrain(x0,y0){
		//xo is x-offset
		//yo is y-offset
		//calculate unit length multiplier
		const svgWidth = this.drawTgt.getBoundingClientRect().width;
		//set height to same as width
		this.drawTgt.setAttribute('height',svgWidth*.8);
		const ulm = svgWidth/14;
		//add some frames using ulm units
		this.drawFrame(ulm,8.7*ulm,12*ulm,2*ulm);
		//vertexes based on a unit 1
		const V = [
			[8,7],//1
			[1,5],//2
			[4,5.8],//3
			[6,5.5],//4
			[9,5.5],//5
			[11,5],//6
			[0,3],//7
			[2,3],//8
			[2.5,4],//9
			[4.5,4],//10
			[6,4],//11
			[9.5,4],//12
			[11.5,4],//13
			[11,3],//14
			[2,1],//15
			[3,2],//16
			[3.5,1.5],//17
			[4,2.8],//18
			[5,2],//19
			[6.5,2],//20
			[7,2.7],//21
			[9,1.5],//22
			[9.5,2],//23
			[5,0],//24
			[6.5,0],//25
			[7.5,1],//26
			[8,1],//27
			[8,0],//28
			[10,1],//29
		];
		//adjacency list 1 indexed
		const adj = [
			[4,5],//1
			[3,7],//2
			[4],//3
			[11],//4
			[6],//5
			[12,13],//6
			[8,15],//7
			[9],//8
			[],//9
			[11],//10
			[],//11
			[14],//12
			[14],//13
			[29],//14
			[16,24],//15
			[17],//16
			[],//17
			[19],//18
			[24],//19
			[21,25],//20
			[],//21
			[23,29],//22
			[],//23
			[25],//24
			[28],//25
			[27],//26
			[28],//27
			[29],//28
			[],//29
		];
		this.drawGraph(V,adj)(x0,y0,ulm);
	}

	drawCertificate(){
		//xo is x-offset
		//yo is y-offset
		//calculate unit length multiplier
		const svgWidth = this.drawTgt.getBoundingClientRect().width;
		//set height to same as width
		this.drawTgt.setAttribute('height',svgWidth*3/2);
		const ulm = svgWidth/10;
		//draw
		//outline
		this.drawRect(ulm,ulm,8*ulm,13*ulm);
		//text blocks
		this.drawTextBlock(2,2,6,1,ulm);
		this.drawTextBlock(2,7,6,4,ulm);
		this.drawTextBlock(2,12,6,3,ulm);
		/*
		lines.forEach(l=>{
			this.drawLine(l[0]*ulm,l[1]*ulm,l[2]*ulm,l[3]*ulm);
		})*/
		//frames
		this.drawFrame(2*ulm,3*ulm,6*ulm,3*ulm);
		this.drawFrame(2*ulm,9.5*ulm,2.5*ulm,2*ulm);
		this.drawFrame(5.5*ulm,9.5*ulm,2.5*ulm,2*ulm);
	}
}

export default Blueprint