import React, {useEffect,useRef} from 'react'

import Blueprint from '../../../../extras/Blueprint/Blueprint'

const Programming = props => {
	const svgRef = useRef(null)
	useEffect(()=>{
		if(svgRef.current) new Blueprint(svgRef.current).drawHand(2,2)
	})
	return (
		<div style={{textAlign:'justify'}}>
				<svg 
					style={{
						float:'right',
						margin:'0 0 16px 16px',
						borderRadius:'4px',
						border:'1px solid #1f1f1f',
						boxShadow:'0 0 4px rgba(0,0,0,0.2)'
					}} 
					width="300" 
					height="400" 
					ref={svgRef}>
					<defs>
						<pattern id="smallGrid" width="8" height="8" patternUnits="userSpaceOnUse">
							<path d="M 8 0 L 0 0 0 8" fill="none" stroke="#99b" strokeWidth="0.5"></path>
						</pattern>
						<pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
							<rect width="80" height="80" fill="url(#smallGrid)"></rect>
							<path d="M 80 0 L 0 0 0 80" fill="none" stroke="#99b" strokeWidth="1"></path>
						</pattern>
					</defs>

					<rect width="100%" height="100%" fill="url(#grid)"></rect>
				</svg>
				<h3>Scripting</h3>
				<p>
					Nate has written robust scripts that download and convert hundreds of videos to GIFs while simultaneously synchronizing databases with the generated file names (Python). 
					Additionaly, Nate has recovered live user data from broken databases schemes & migrations (PHP).
				</p>
				<h3>Abstraction</h3>
				<p>
					Abstraction is the modus operandi for Nate as he writes code - he has seen first hand how terribly code rot accumulates with poor abstractions. This is actually Nate's 
					favorite part of programming - designing and collaborating on powerful abstractions that strategically capture business logic into readable and maintainable code. 
				</p>
				<h3>Optimization</h3>
				<p>
					Nate understands how to leverage datastructures to optimize code runtime - he was honestly suprised to see how often basic structures like sets with O(1) look ups can be used
					to speed up common tasks like running complex filters/maps over arrays. 
				</p>
				<h3>Practice</h3>
				<p>
					Nate does not assume that he knows everything about programming so his first thoughts are always to research
					best practices so that he does repeat easily avoidable mistakes.
				</p>
			</div>
	)
}

export default Programming